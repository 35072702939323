
@font-face {
  font-family: Satoshi-400;
  src: url("./fonts/Satoshi/Satoshi-Light.otf");
}
@font-face {
  font-family: Satoshi-500;
  src: url("./fonts/Satoshi/Satoshi-Regular.otf");
}
@font-face {
  font-family: Satoshi-600;
  src: url("./fonts/Satoshi/Satoshi-Medium.otf");
}
@font-face {
  font-family: Satoshi-700;
  src: url("./fonts/Satoshi/Satoshi-Bold.otf");
}
@font-face {
  font-family: Satoshi-900;
  src: url("./fonts/Satoshi/Satoshi-Black.otf");
}
@font-face {
  font-family: RobotoMono-500;
  src: url("./fonts/Satoshi/RobotoMono-Medium.ttf");
}
.swal2-popup {
  font-family:"Satoshi-500";
}

.br-100 {
  border-radius: 100%;
}
.w-400 {
  font-weight: 400 !important;
  font-family: Satoshi-400 !important;
}
.w-500 {
  font-weight: 500 !important;
  font-family: Satoshi-500 !important;
}
.w-600 {
  font-weight: 600 !important;
  font-family: Satoshi-500 !important;
}
.w-700 {
  font-weight: 700 !important;
  font-family: Satoshi-700 !important;
}
.w-900 {
  font-weight: 900 !important;
  font-family: Satoshi-900 !important;
}
.roboto-w-500 {
  font-weight: 500 !important;
  font-family: RobotoMono-500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.fixed-header {
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0px;
}
.alignC {
  align-items: center;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.cust-header {
  transition: 0.5s !important;
}
.border-box {
  box-sizing: border-box;
}
.trans-header a {
  color: #1976d2 !important;
}
.trans-header a.MuiButton-containedPrimary {
  color: white !important;
}
.in-container {
  max-width: 1280px;
  margin: auto;
}
.in-container h3 {
  font-size: 32px;
  line-height: 43px;
  font-weight: 700;
}
.in-container h6 {
  font-size: 24px;
  line-height: 32px;
}
.in-container .form-input .MuiTypography-root {
  font-family: Satoshi;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #56617b;
}
.s-1216 {
  font-size: 12px;
  line-height: 16px;
}
.s-1419 {
  font-size: 14px !important;
  line-height: 19px !important;
}
.s-1420 {
  font-size: 14px !important;
  line-height: 20px !important;
}
.s-1421 {
  font-size: 14px;
  line-height: 21px;
}
.s-1422 {
  font-size: 14px !important;
  line-height: 22px !important;
}
.s-1622 {
  font-size: 16px !important;
  line-height: 22px !important;
}
.s-1624 {
  font-size: 16px !important;
  line-height: 24px !important;
}
.s-1824 {
  font-size: 18px !important;
  line-height: 24px !important;
}
.s-2027 {
  font-size: 20px !important;
  line-height: 27px !important;
}
.s-2032 {
  font-size: 20px !important;
  line-height: 32px !important;
}
.s-2422 {
  font-size: 24px !important;
  line-height: 22px !important;
}
.s-3222 {
  font-size: 32px !important;
  line-height: 22px !important;
}
.s-3622 {
  font-size: 36px !important;
  line-height: 22px !important;
}

.s-2838 {
  font-size: 28px;
  line-height: 38px;
}
.s-4872 {
  font-size: 48px;
  line-height: 72px;
}
.line {
  height: 0px;
  border: 1px solid #e8ebf2;
}
.upload-doc {
  background: #f4f6fa;
  border: 1px dashed #e8ebf2;
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 28px;
}
.bt-explore button {
  height: 48px;
  border: 1px solid #2457b9;
  border-radius: 8px;
  outline: none;
}
.bt-explore button.wd-180 {
  background: linear-gradient(270deg, #2457b9 15%, #2860cd 100%);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
}
.ml-22 {
  margin-left: 22px;
}
.ml8 {
  margin-left: 8px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-18 {
  margin-top: 18px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-90 {
  margin-top: 90px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-52 {
  margin-bottom: 52px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mb-104 {
  margin-bottom: 104px;
}
.mr4 {
  margin-right: 4px;
}
.mr8 {
  margin-right: 8px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.c-56 {
  color: #56617b !important;
}
.c-error {
  color: #ed3131;
}
.c-08 {
  color: #081130 !important;
}
.c-87 {
  color: #8792ab;
}
.c-24 {
  color: #2457b9 !important;
}
.c-ff {
  color: #ffffff !important;
}
.bg-ff {
  background-color: #ffffff;
}
.wd-160 {
  width: 160px;
}
.wd-180 {
  width: 180px;
}
.wd-185 {
  width: 185px;
}
.wd-252 {
  max-width: 252px !important;
}
.txt-none-tranform {
  text-transform: none !important;
}

.txt-align-center {
  text-align: center  !important;
}

.lineheight-normal {
  line-height: normal !important;
}

.MuiDateRangeCalendar-root div {
  color: transparent !important;

  z-index: 1;
}
.dateRange .MuiFormLabel-root.MuiInputLabel-root,
.dateRange legend {
  display: none;
}
.MuiStack-root.box-group-select,
.MuiStack-root.box-group-select {
  width: 100% !important;
}
.MuiStack-root.box-group-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-radio .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  /* width: calc(50% - 10px) !important; */
  height: 48px;
  border: 2px solid #e8ebf2;
  border-radius: 8px;
}

.form-radio-select .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  /* width: calc(50% - 10px); */
  height: 48px;
  border: 2px solid #3ccb0d;
  border-radius: 8px;
}

.form-radio-error .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  /* width: calc(50% - 10px); */
  height: 48px;
  border: 2px solid #d32f2f;
  border-radius: 8px;
}


.form-radio-select .MuiFormControlLabel-root:focus {
  border: 2px solid #3ccb0d;
}

.form-radio-multiple .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 100% !important;
  height: 48px;
  border: 2px solid #e8ebf2;
  border-radius: 8px;
}

.form-radio-select-multiple .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 100% !important;
  height: 48px;
  border: 2px solid #3ccb0d;
  border-radius: 8px;
}

.form-radio-error-multiple .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 100% !important;
  height: 48px;
  border: 2px solid #d32f2f;
  border-radius: 8px;
}

.form-radio-select-multiple .MuiFormControlLabel-root:focus {
  border: 2px solid #3ccb0d;
}
.is-gapless.box {
  display: none;
}
.pdf-viewer div {
  height: 92px !important;
}
.pdf-viewer div canvas {
  width: 100% !important;
}
.doc_ {
  width: calc(50% - 9px);
  margin-bottom: 50px;
  border: 2px solid #e8ebf2;
  border-radius: 4px;
}
.box-document {
  height: 92px;
  overflow: auto;
  width: 100%;
  background: #f4f6fa;
  border-bottom: 2px solid #e8ebf2;
  padding: 8px;
}
.box-document-claim {
  height: 685px;
  /* overflow: auto; */
  width: 100%;
  background: #f4f6fa;
  border-bottom: 2px solid #e8ebf2;
  padding: 8px;
}
.doc-desc {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

.doc-desc-company {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  align-items: center;
}
.pdf-viewer .container.text-center div {
  overflow: hidden !important;
}
.box-document::-webkit-scrollbar,
.in-container .MuiDataGrid-virtualScrollerRenderZone::-webkit-scrollbar,
.in-container .MuiDataGrid-virtualScroller::-webkit-scrollbar,
div .MuiDataGrid-virtualScrollerRenderZone::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /* overflow: hidden !important; */
}
.selected-company {
  margin-top: 8px;
  border-top: 1px solid #e8ebf2;
  background: #ffffff;
  /* border: 1px solid #e8ebf2; */
  border-left: 1px solid #e8ebf2;
  border-right: 1px solid #e8ebf2;
  border-radius: 8px;
  border-bottom: 1px solid #e8ebf2;
}
div .pointer {
  cursor: pointer;
}
.selected-company .ic-company {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  margin-right: 16px;
}
.selected-company .box {
  padding: 16px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8ebf2;

  /* border-radius: 8px; */
}
.selected-company .box::first-child {
  border-top: 1px solid #e8ebf2;
  border-top-left-radius: 8px solid #e8ebf2;
  border-top-right-radius: 8px solid #e8ebf2;
}
.signup .ReactInputVerificationCode__item {
  width: 43px;
  height: 56px;
  padding: 0px;
  border-radius: 0px;
  font-size: 28px;
  line-height: 38px;
  font-weight: 800;
  font-family: Satoshi-700;
  text-align: center;
  border: 0px;
  box-shadow: unset !important;
  transition: box-shadow 0.2s ease-out 0s;
  border-bottom: 1px solid #818ca7;
  color: #081130;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup .ReactInputVerificationCode__item.is-active {
  border-color: #2457b9;
}

.ex-ui .MuiDataGrid-footerContainer {
  display: none;
}
svg.MuiSvgIcon-root path {
  opacity: 1;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #2457b9 !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  box-shadow: unset !important;
}
.box-location {
  /* height: 440px; */
  width: 591px;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
  border: 1px solid gray;
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  z-index: 2;
  margin-top: -10px;
}
.box-location .MuiFormGroup-root {
  width: 100%;
  display: flex;
  align-items: start;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  flex-direction: row;
  overflow: auto !important;
  /* height: 360px; */
}
.box-location span.MuiTypography-root {
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  line-height: 18px;
}
.box-filter-drawer .css-ahj2mt-MuiTypography-root {
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  line-height: 18px;
}

.box-location .MuiFormGroup-root label {
  width: 32%;
  margin-left: 0px;
  margin-right: 0px;
}
div .MuiDataGrid-virtualScrollerRenderZone,
div .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row {
  transition: 0.3s;
}

.box-drawer .MuiPaper-root {
  height: calc(60%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.box-drawer-more .MuiPaper-root {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.box-drawer-upload-doc .MuiPaper-root {
  height: calc(80%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.box-drawer-upload-doc-form .MuiPaper-root {
  /* height: calc(80%); */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.box-filter-drawer .MuiPaper-root {
  font-family: Satoshi-500 !important;
  height: calc(80%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.box-filter-drawer-trader .MuiPaper-root {
  font-family: Satoshi-500 !important;
  height: calc(60%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.form-radio-perusahaan .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 127px;
  height: 48px;
  border: 2px solid #e8ebf2;
  border-radius: 8px;
}

.form-radio-perusahaan-error .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 127px;
  height: 48px;
  border: 2px solid #d32f2f;
  border-radius: 8px;
}

.form-radio-perusahaan-select .MuiFormControlLabel-root {
  margin: 0px 0px 14px 0px;
  width: 127px;
  height: 48px;
  border: 2px solid #3ccb0d;
  border-radius: 8px;
}
.form-radio-perusahaan .MuiFormControlLabel-root:focus {
  border: 2px solid #3ccb0d !important;
}

.homepage .box-slide.width-100 .slick-slider button {
  display: flex !important;
  position: absolute;
  top: 82%;
}
.homepage .box-slide.width-100 .slick-slider button.slick-prev {
  left: -30%;
  /* background-color: #3ccb0d; */
}
.homepage .box-slide.width-100 .slick-slider button.slick-next {
  left: calc(-30% + 75px);
}

img.slick-next {
  margin-top: 89px;
  left: -25%;
  transform: rotate(180deg);
}
.homepage .box-slide .slick-next:before,
.homepage .box-slide .slick-prev:before {
  content: "";
}

.homepage div .box-slide.width-100 .slick-slider button:active {
  background-color: white;
  color: #2550a2 !important;
}

img[alt~="copy-icon"]:active {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}
.copyToClipboard {
  background: #e8ebf2;
  color: #56617b;
  height: 35px;
  border-radius: 5px;
  width: fit-content;
  padding: 0px 10px;
  margin-top: 24px;
  font-size: 14px;
  transition: 0.3s;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
}
.none,
.more.none {
  display: none;
}
.flag .MuiSelect-select img {
  width: 30px;
  height: 21px;
  margin-right: 10px;
  object-fit: contain;
}
.flag .MuiSelect-select {
  display: flex;
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.img-tongkang {
  width: 130px;
  height: 77px;
  border-radius: 5px;
  object-fit: cover;
}
.laycan-date .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}
ul.MuiList-root li.MuiButtonBase-root img {
  width: 30px;
  height: 21px;
  margin-right: 10px;
  object-fit: contain;
}

/* Slider Rekomendasi Tongkang; */
.recomendation-tongkang .box-slide.width-100 .slick-slider button {
  display: flex !important;
  position: absolute;
  top: 37%;
}

.recomendation-tongkang .box-slide.width-100 .slick-slider button.slick-prev {
  left: -1%;
  /* background-color: #3ccb0d; */
}
.recomendation-tongkang .box-slide.width-100 .slick-slider button.slick-next {
  right: calc(-8% + 75px);
}

.recomendation-tongkang .box-slide .slick-next:before,
.recomendation-tongkang .box-slide .slick-prev:before {
  content: "";
}

.recomendation-tongkang div .box-slide.width-100 .slick-slider button:active {
  background-color: white;
  color: #2550a2 !important;
}

/* Slider Image Tongkang; */
.slider-detail-tongkang .box-slide.width-100 .slick-slider button {
  display: flex !important;
  position: absolute;
  top: 53%;
}

.slider-detail-tongkang .box-slide.width-100 .slick-slider button.slick-prev {
  left: -2%;
  /* background-color: #3ccb0d; */
}
.slider-detail-tongkang .box-slide.width-100 .slick-slider button.slick-next {
  right: calc(-10% + 75px);
}

.slider-detail-tongkang .box-slide .slick-next:before,
.slider-detail-tongkang .box-slide .slick-prev:before {
  content: "";
}

.slider-detail-tongkang div .box-slide.width-100 .slick-slider button:active {
  background-color: white;
  color: #2550a2 !important;
}

/* RESPONSIVE HOMEPAGE START  */
@media screen and (min-width: 1601px) {
  .homepage div .box-slide.width-100 .slick-slider button {
    top: 77%;
  }

  .recomendation-tongkang div .box-slide.width-100 .slick-slider button {
    top: 77%;
  }

  .slider-detail-tongkang div .box-slide.width-100 .slick-slider button {
    top: 77%;
  }
}

@media screen and (min-width: 1200px) {
  .av-profile {
    width: 160px !important;
    height: 160px !important;
  }
}
@media screen and (max-width: 1238px) and (min-width: 1200px) {
  .homepage div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 15px);
  }

  .recomendation-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 15px);
  }

  .slider-detail-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 15px);
  }
}

@media screen and (max-width: 1199px) and (min-width: 929px) {
  .homepage div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 100px);
  }
  .homepage div .box-slide.width-100 .slick-slider button.slick-prev {
    left: -51%;
  }
  .homepage .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }

  .recomendation-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 100px);
  }
  .recomendation-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -51%;
  }
  .recomendation-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }

  .slider-detail-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 100px);
  }
  .slider-detail-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -51%;
  }
  .slider-detail-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }
}

@media screen and (max-width: 928px) and (min-width: 900px) {
  .homepage div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 75px);
  }
  .homepage div .box-slide.width-100 .slick-slider button.slick-prev {
    left: -51%;
  }
  .homepage .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }

  .recomendation-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 75px);
  }
  .recomendation-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -51%;
  }
  .recomendation-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }

  .slider-detail-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(100% - 75px);
  }
  .slider-detail-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -51%;
  }
  .slider-detail-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(-51% + 75px);
  }
}
@media screen and (max-width: 899px) and (min-width: 641px) {
  .homepage div .box-slide.width-100 .slick-slider button {
    top: calc(3% - 75px);
  }
  .homepage div .box-slide.width-100 .slick-slider button.slick-prev {
    left: 0%;
  }
  .homepage .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(0% + 75px);
  }
  .homepage .flex-center.mb100 {
    margin-bottom: 100px;
  }

  .recomendation-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(1% - 75px);
  }
  .recomendation-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -2% !important;
  }
  .recomendation-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(0% + 75px);
  }

  .slider-detail-tongkang div .box-slide.width-100 .slick-slider button {
    top: calc(1% - 75px);
  }
  .slider-detail-tongkang
    div
    .box-slide.width-100
    .slick-slider
    button.slick-prev {
    left: -2% !important;
  }
  .slider-detail-tongkang .box-slide.width-100 .slick-slider button.slick-next {
    left: calc(0% + 75px);
  }
}
@media screen and (max-width: 599px) {
  .more.none {
    display: flex;
  }
}

.MuiButtonBase-root.MuiButton-root.button-blue-outlined {
  border: 1px solid var(--primarry-blue, #2457b9);
  color: var(--primarry-blue, #2457b9);
  text-transform: none;
  border-radius: 8px;
  padding: 12px 16px;
  height: 44px
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.button-blue-contained {
  padding: 12px 16px;
  height: 44px;
  text-transform: none;
  border-radius: 8px;
  background: var(
    --button-blue,
    linear-gradient(270deg, #2457b9 0%, #2860cd 100%)
  );
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.12),
    0px -1px 0px 0px rgba(0, 0, 0, 0.2) inset;
}

/* RESPONSIVE HOMEPAGE END  */

/* LOGIN PAGE  */
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.box-input-email
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px !important;
  margin-bottom: 24px;
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.box-input-password
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px;
  margin-bottom: 16px;
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 44px;
  max-height: 48px;
}

.MuiFormControl-root.MuiTextField-root.search-filter-box
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  font-family: Satoshi !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.placeholderflag
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  line-height: 18px;
  /* padding: 6px !important; */
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.select-flag
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  line-height: 18px;
  /* padding: 6px !important; */
}

.MuiFormControl-root.MuiTextField-root.search-filter-box input {
  padding-left: 0 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MuiFormControl-root.MuiTextField-root.box-input-filter
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  height: 48px;
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px;
  font-family: 'Satoshi-500';
  font-size: 14px;
  font-style: normal;

}
.MuiFormControl-root.MuiTextField-root.box-input-filter
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary.box-form-currency {
  border: none !important;
  padding: none !important;
}

.MuiFormControl-root.MuiTextField-root.box-input-filter input {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MuiFormControl-root.MuiTextField-root.box-input-form
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  height: 48px;
  padding-left: 0;
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px;
  font-family: 'Satoshi-500';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.MuiFormControl-root.MuiTextField-root.box-input-form
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary.box-form-currency {
  border: none !important;
  padding: none !important;
}



.MuiFormControl-root.MuiTextField-root.box-input-form input {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MuiFormControl-root.MuiTextField-root.box-text-area
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px;
  font-family: 'Satoshi-500';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.MuiFormControl-root.MuiTextField-root.box-text-area input {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: '#8792AB'; 
  font-size: '14px'; 
  font-family: 'Satoshi-500';
}

.MuiFormControl-root.MuiTextField-root.box-deskripsi-company
  .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 8px !important;
  border: 1px solid var(--grey-stroke, #e8ebf2) !important;
  margin-top: 4px;
  font-family: 'Satoshi-500';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0;
}

.MuiFormControl-root.MuiTextField-root.box-deskripsi-company input {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.location-label .css-ahj2mt-MuiTypography-root {
  font-family: Satoshi-500 !important ;
  font-size: 14px;
  line-height: 18px;
}

.MuiFormControl-root.MuiTextField-root.box-text-komentar
  .MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary {
  font-family: Satoshi-400 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.MuiFormControl-root.MuiTextField-root.box-text-komentar input {
  padding-top: 12px !important;
  /* padding-bottom: 12px !important; */
}

#header-bar {
  display: none;
}

#pdf-controls {
  display: none;
}

#react-doc-viewer {
  height: 100%;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  border: unset !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
  border-radius: 8px !important;

  font-size: 14px !important;
  font-family: Satoshi-500 !important;
}

.MuiAutocomplete-endAdornment {
  margin-right: 9px !important;
}

.Calendar.-noFocusOutline.-ltr {
  height: 332px !important;
  width: 100%;
  border: 2px solid #e8ebf2 !important;
  box-shadow: unset !important;
}

.Calendar {
  min-height: 332px !important;
  font-weight: 400 !important;
  font-family: Satoshi-400 !important;
  color: #000000 !important;
}

.Calendar__day {
  color: #081130 !important;
}

.Calendar__day.-ltr:hover {
  border-radius: unset !important;
}

.Calendar__day.-selected {
  color: #ffffff !important;
}

.Calendar__day.-ltr.-selectedStart {
  border-radius: unset !important;
  background-color: #3bb56c;
  color: #ffffff !important;
}

.Calendar__day.-ltr.-selectedEnd {
  border-radius: unset !important;
  background-color: #3bb56c;
  color: #ffffff !important;
}

.Calendar__day.-selectedBetween {
  background-color: rgba(59, 181, 108, 0.25) !important;
}

.Calendar__header {
  padding: 12px 18px 22px 18px !important
}

.Calendar__weekDay {
  margin-bottom: 0 !important;
  font-weight: 600 !important;
  font-family: Satoshi-600 !important;
  color: #000000 !important;
}

.apexcharts-tooltip {
  background: #256AF5 !important;
  color: #ffffff;
}

.apexcharts-tooltip-title {
  background: #256AF5 !important;
  color: #ffffff;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {

border-bottom: none !important;
}

