:root {
  --blue: #1e90ff;
  --primary-grey: #081130;
  --secondary-grey: #56617b;
  --gray-lightest: #e8ebf2;
  --white: #ffffff;
}
html {
  scrollbar-width: none;
}
body {
  float: left;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
}
body::-webkit-scrollbar {
  display: none;
}

#home.box-wrapper {
  overflow: hidden !important;
}

@font-face {
  font-family: Satoshi-300;
  src: url("./fonts/Satoshi/Satoshi-Light.otf");
}
@font-face {
  font-family: Satoshi-400;
  src: url("./fonts/Satoshi/Satoshi-Regular.otf");
}
@font-face {
  font-family: Satoshi-500;
  src: url("./fonts/Satoshi/Satoshi-Medium.otf");
}
@font-face {
  font-family: Satoshi-700;
  src: url("./fonts/Satoshi/Satoshi-Bold.otf");
}
@font-face {
  font-family: Satoshi-900;
  src: url("./fonts/Satoshi/Satoshi-Black.otf");
}
.relative {
  position: relative;
}
.width-100,
.width-50 {
  /* float: left; */
}

.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}

.flex-start,
.flex-end,
.flex-center,
.flex-wrap,
.flex-wrap-start,
.flex-wrap-center,
.flex-wrap-end,
.flex-wrap-between,
.flex-between {
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-wrap-start {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-wrap-center {
  flex-wrap: wrap;
  justify-content: center;
}

.flex-wrap-end {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.flex-wrap-between {
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-between {
  justify-content: space-between;
}
.img-fluid {
  max-width: 100%;
}
.img-cover {
  object-position: center;
  object-fit: cover;
}
.img-containt {
  object-position: center;
  object-fit: contain;
}
.text-center {
  text-align: center;
}
.box-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.wrapper {
  float: left;
  width: 100%;
}
.box-wrapper .content {
  float: left;
  width: 100%;
  position: relative;
}
.content-inner {
  width: calc(100% - 100px);
  max-width: 1440px;
}
.btn-signin {
  border: 1px solid transparent !important;
  color: #fff !important;
}
.banner .img-banner {
  position: absolute;
  height: 523px;
}
.banner-title {
  margin: 126px 0 42px 0;
}
.banner-title h1 {
  color: var(--white);
  max-width: 964px;
}
.react-multi-carousel-item {
  padding: 0 10px;
}
.box-mine-category {
  gap: 44px;
}
.mine-category {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.5s ease;
}
.mine-category:hover {
  opacity: 1;
  transition: 0.5s ease;
}
.mine-container-company {
  margin: 50px 0;
  background-color: var(--white);
}

.mine-container-company h4 {
  font-family: "Satoshi-700";
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: var(--primary-grey);
}
.mine-container-company p {
  color: var(--secondary-grey);
}
.mine-category img {
  height: 68px;
  width: 68px;
}
.mine-company-item {
  float: left;
  width: 434px;
  padding: 12px;
}
.company-item-inner {
  height: 220px;
  background: var(--white);
  border: 1px solid var(--gray-lightest);
  border-radius: 8px;
}
.css-1w7v7sf-MuiGrid-root {
  max-width: 1302px;
}
.box-slide {
  max-width: 1903px;
}
.box-slide .slick-list {
  padding: 0px 40px 0px 0px !important;
}
.img-register {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: -1;
}
.banner .MuiInputBase-formControl {
  border-radius: 8px !important;
  padding: 8px 8px 8px 20px !important;
}
.MuiInputBase-inputTypeSearch {
  font-family: Satoshi-400 !important;
  padding: 8.5px 0 !important;
}
.ex-ui .css-yph9fj-MuiDataGrid-root {
  border-radius: 12px;
}
.css-1cgs3vu-MuiPaper-root-MuiCard-root,
.css-1sk6b3a-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}
/* 
.css-yph9fj-MuiDataGrid-root {
  font-family: Satoshi-500 !important;
  color: #081130 !important;
}
.css-yph9fj-MuiDataGrid-root .MuiDataGrid-columnHeader {
  border-right: 1px solid #e8ebf2 !important;
} */
.MuiButton-endIcon {
  margin-right: 0px !important;
}
.MuiButton-endIcon > div {
  border-radius: 4px !important;
}
.menu-user .MuiPaper-elevation {
  border-radius: 8px;
}
.menu-user .MuiDivider-root {
  margin: 0 !important;
}
.menu-user .MuiMenu-list {
  /* width: 160px !important; */
  padding: 0 !important;
}

.menu-joincompany .MuiPaper-elevation {
  border-radius: 8px;
}
.menu-joincompany .MuiDivider-root {
  margin: 0 !important;
}
.menu-joincompany .MuiMenu-list {
  width: 160px !important;
  padding: 0 !important;
}

.form-input .MuiInputBase-formControl {
  font-family: "Satoshi-400";
  border-radius: 8px;
}
.form-input .MuiInputBase-input {
  padding: 14px 16px;
  font-family: "Satoshi-400";
  font-size: 14px;
  line-height: 20px;
}
.form-input .MuiInputAdornment-root .MuiTypography-root {
  font-family: "Satoshi-700";
  font-size: 14px;
  margin-right: 8px;
}

.form-input .MuiInputBase-inputAdornedStart {
  border-left: 1px solid #e8ebf2;
}
.left-grid-explore-page .MuiInputBase-inputSizeSmall {
  height: 24px !important;
}

.company-photo {
  height: 60px;
}
@media screen and (max-width: 1280px) {
  /* insert here's style for screen  and (min-width:1025px) and (max-width: 1440px) */
  .wrapper {
    max-width: 1440px;
  }
  .in-container {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 960px) {
  /* insert here's style for screen  and (min-width:768px) and (max-width: 1024px) */
  .wrapper {
    max-width: 1280px;
  }
  .MuiPopover-root.MuiMenu-root {
    z-index: 999999;
  }

  /* insert here's style for screen  and (min-width:569px) and (max-width: 767px) */
  /* .wrapper {
    max-width: 767px;
  } */
  .content-inner {
    width: calc(100% - 100px);
    max-width: 100%;
  }
  .banner .img-banner {
    height: 100%;
  }
  .banner-title {
    margin: 88px 0 51px 0;
  }

  .homepage .s-4872 {
    font-size: 6vmax;
    line-height: 8vmax;
  }
  .box-mine-category {
    gap: 28px;
  }
  .mine-category img {
    height: 54px;
    width: 54px;
  }
}
@media screen and (max-width: 900px) {
  .company-photo {
    height: 40px;
  }
}
@media screen and (max-width: 599px) {
  /* insert here's style for sscreen  and (min-width:481px) and (max-width: 568px) */
  .content-inner {
    width: calc(100% - 32px);
  }
  .homepage .s-4872 {
    font-size: 6vmax;
    line-height: 7.5vmax;
  }
  .box-slide .slick-list {
    padding: 0px 10px 0px 0px !important;
  }
}
@media screen and (max-width: 480px) {
  .homepage .s-4872 {
    font-size: 6vmin;
    line-height: normal;
  }
}

.tab-scroll-x::-webkit-scrollbar {
  width: 0 !important;
}

.tab-scroll-x {
  display: flex !important;
  overflow-x: auto !important;
  max-width: "1000px" !important;
}

/* custom based breakpoint Mui */
@media (min-width: 600px) and (max-width: 959px) {
  /* responsive explore page */
  .left-grid-explore-page {
    flex-grow: 0 !important;
    max-width: none !important;
    flex-basis: auto !important;
  }

  .right-grid-explore-page {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiGrid-grid-sm-auto {
    flex-grow: 0 !important;
    max-width: none !important;
    flex-basis: auto !important;
  }

  .MuiGrid-grid-sm-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-sm-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-sm-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-sm-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-sm-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-sm-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-sm-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-sm-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-sm-12 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
@media (min-width: 960px) and (max-width: 1439px) {
  /* responsive explore page */
  .left-grid-explore-page {
    flex-grow: 0 !important;
    max-width: 25% !important;
    flex-basis: 25% !important;
  }

  .right-grid-explore-page {
    flex-grow: 0 !important;
    max-width: 75% !important;
    flex-basis: 75% !important;
  }

  .search-explore-page {
    /* flex-grow: 0 !important;
    max-width: 80% !important;
    flex-basis: 80% !important; */
  }
  .searchtrader-explore-page {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .btn-search-explore-page {
    flex-grow: 0 !important;
    max-width: 20% !important;
    flex-basis: 20% !important;
  }

  .MuiGrid-grid-md-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }
  .MuiGrid-grid-md-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }
  .MuiGrid-grid-md-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }
  .MuiGrid-grid-md-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
  .MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .MuiGrid-grid-md-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiGrid-grid-md-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
  .MuiGrid-grid-md-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }
  .MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 1440px) {
  .search-explore-page {
    max-width: 799px !important;
    /* flex-grow: 0 !important;
    max-width: 75% !important;
    flex-basis: 75% !important; */
  }

  MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.btn-search-explore-page {
    padding-right: 0 !important;
  }

  .searchtrader-explore-page {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .btn-search-explore-page {
    max-width: 160px !important;
    /* flex-grow: 0 !important;
    max-width: 25% !important;
    flex-basis: 25% !important; */
  }
}
.MuiGrid-root.MuiGrid-container.freeze-grid-new {
  margin: 0;
  /* padding-left: 16px;
  padding-right: 16px; */
  align-items: center !important;
  background-color: white;
  position: fixed !important;
  bottom: 0%;
  height: 84px;
  z-index: 99;
}

.MuiGrid-root.MuiGrid-container.disable-freeze-grid-new {
  margin: 0;
  /* padding-left: 16px;
  padding-right: 16px; */
  align-items: center !important;
  background-color: white;
  /* position: fixed !important; */
  /* bottom: 0%; */
  height: 84px;
  z-index: 99;
}
